import React from 'react'
import styled from 'styled-components'
import { RED, } from './Constants'
import bulb from './bulb.png'
import Button from './Button'

const Body = styled.li`
  text-align: left;
  font-size: 20px;
  box-shadow: none;
  margin-bottom: 0px;
  list-style-position: outside;
`

const Red = styled.span`
  color: ${RED};
`

const Green = styled.span`
  color: green;
`

const Rules = ({ onClick, }) => (
  <div
    style={{
      width: 800,
      minHeight: 400,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 30,
      margin: 20,
      boxShadow: '0px 0px 10px 0px white',
      fontFamily: 'avenir',
    }}>
    <img
      alt="lightbulb-icon"
      height={90}
      src={bulb} />
    <div>
      <h2 style={{ textAlign: 'center', fontSize: 32, }}>HOW TO PLAY</h2>
      <ul>
        <Body>
          Read the question and review all answer choices. Select the answer you believe is correct.
        </Body>
        <Body>
          You earn 100 points per question when you select the <Green>correct answer</Green>.
        </Body>
        <Body>
          You lose 25 points when you select an <Red>incorrect answer</Red>.
        </Body>
        <Body>
          When you finish the quiz, submit your score to enter the raffle.
          (Your score will increase your chances of winning. You may play twice to improve your score.)
        </Body>
      </ul>
    </div>
    <Button onClick={onClick}>
      PLAY
    </Button>
  </div>
)

export default Rules
