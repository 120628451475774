import React from 'react'

const opioidsEmployer = [
  // 1
  {
    questionText: "As a cause of death among employees under 50, overdoses rank:",
    answers: [
      {
        answerText: "First",
        explanation: <p>CORRECT. This is also the <a href='https://www.nytimes.com/interactive/2017/06/05/upshot/opioid-epidemic-drug-overdose-deaths-are-rising-faster-than-ever.html'>fastest-growing cause of death</a>.</p>,
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Second, behind heart attacks",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Third, behind heart attacks and accidents/injuries",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Fourth, behind heart attacks, accidents/injuries and HIV/AIDS",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false            
      },
      {
        answerText: "Fifth, behind heart attacks, accidents/injuries, HIV/AIDS, and homicides+suicides",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false            
      }
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: null,
    harvardApproved: false
  },
  // 2
  {
    questionText: "The fastest-growing condition/illness in most employee populations is:",
    answers: [
      {
        answerText: "Diabetes",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Heart disease",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Cancer",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Addiction",
        explanation: <p>CORRECT. Addiction diagnosis has <a href='https://www.wsj.com/articles/opioid-addiction-diagnoses-up-nearly-500-in-past-seven-years-study-shows-1498737603'>increased nearly 500% in seven years</a>. To put this in perspective, heart attacks have declined, while diabetes hospitalization events are roughly unchanged for the employee populations.</p>,
        isCorrect: true,
        wasSelected: false            
      },
      {
        answerText: "Obesity",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      }
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: null,
    harvardApproved: false 
  },
  // 3
  {
    questionText: "Excluding post-operative prescriptions, the percentage of people who receive one 10-day prescription for painkillers who then continue to use them for at least a year is:",
    answers: [
      {
        answerText: "10%",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "20%",
        explanation: <p>CORRECT. "Only" 20% who take opioids for <a href='https://arstechnica.com/science/2017/03/with-a-10-day-supply-of-opioids-1-in-5-become-long-term-users/'>10 days become long-term addicts</a>. That still makes prescription pain medication by far the most addictive thing one can legally consume.</p>,
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "30%",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "40%",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false                
      },
      {
        answerText: "50%",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: null,
    harvardApproved: false    
  },
  // 4
  {
    questionText: "The percentage of the US adult population believed to be addicted to opioids is:",
    answers: [
      {
        answerText: "1%",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "5%",
        explanation: <p>CORRECT. It was 4.6% in 2013 and is believed to be <a href='https://drugfree.org/wp-content/uploads/2015/04/Matrix_OpioidAbuse_040415.pdf'>higher today</a>. This is up from almost nobody 30 years ago, making opioid addiction the fastest-growing employee health menace.</p>,
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "10%",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "15%",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false                
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: null,
    harvardApproved: false    
  },
  // 5
  {
    questionText: "What are four major side effects/concerns about prescription pain medications like Percocet (oxycdone) or Vicodin (hydrocodone)?",
    answers: [
      {
        answerText: "Cavities",
        explanation: "CORRECT. Opioids dry out your mouth, which sets you up for cavities and gum disease. It is important to keep your mouth moist, using ice chips and/or a humidifier, and a dry-mouth mouthwash, like Biotene.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Constipation",
        explanation: "CORRECT. These medicines interfere with your intestines. Long-term constipation can set you up for a lifetime of intestinal problems.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Needing more and more for the same pain relief.",
        explanation: "CORRECT. Needing more and more of a drug to produce the same effect is called \"tolerance,\" which is like addiction. And the more you take, the more likely you will experience the other side effects.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Nausea, vomiting and diarrhea.",
        explanation: "CORRECT. These drugs wreak havoc on your digestive system from top to bottom.",
        isCorrect: true,
        wasSelected: false                
      },
      {
        answerText: "Toenail fungus",
        explanation: "INCORRECT. It's hard to find something that isn't a side effect. Back story: \"Cavities\" was supposed to be the gag answer until we learned you can get cavities and gum disease because opioids cause dry mouth, which itself causes cavities.",
        isCorrect: false,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: 'https://www.health.harvard.edu/blog/report-highlights-dangers-opioid-painkillers-201501157615',
    harvardApproved: false    
  },
  // 6
  {
    questionText: "Opioid users often develop a tolerance. What is a tolerance?",
    answers: [
      {
        answerText: "It means you can take the drugs in the current dosage without nausea or other short-term side effects",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "It means, because of the lethargy, you are more likely to tolerate behaviors by co-workers that you might otherwise find objectionable",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "It means you need a greater dosage to sustain the relief or feeling of normalcy you initially experienced.",
        explanation: <p>CORRECT. A tolerance requires increasingly higher dosages to get the same relief. The downsides of increasing dosage are the much greater likelihood of side effects and the possibility that employees will resort to illegal means to supplement their current prescriptions with additional pills. For a concise discussion of the difference between tolerance, dependence, and addiction, <a href='https://teens.drugabuse.gov/blog/post/tolerance-dependence-addiction-whats-difference'>click here</a>.</p>,
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "It means your fight-or-flight (anger) response is artificially depressed, leaving you more vulnerable to attacks/muggings/emotional abuse etc.",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false                
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: null,
    harvardApproved: false    
  },
  // 7
  {
    questionText: "The first signs of long-term dependency on prescription opioids begin how long after an employee starts taking them?",
    answers: [
      {
        answerText: "3 hours",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "3 days",
        explanation: <p>CORRECT. See this <a href='http://www.newsweek.com/cdc-opiate-addiction-572498'>CDC study</a>.</p>,
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "3 weeks",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "3 months",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false                
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: null,
    harvardApproved: false    
  },
  // 8
  {
    questionText: "In order to stem the rise in opioid use, employers and other payors on average made what change to the employee co-pay between 2001 and 2012?",
    answers: [
      {
        answerText: "200% increase",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "100% increase",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "50% increase",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "20% increase",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false                
      },
      {
        answerText: "75% decrease",
        explanation: <p>CORRECT. <a href='http://content.healthaffairs.org/content/35/5/824.abstract'>This is likely due</a> to a combination of more generous coverage, incentives to stay on formulary and more days of supply per prescription filled. Any economist will say that substantially reducing price of anything will increase demand. And yet, as opioid use was exploding, PBMs, carriers and employers inadvertently allowed the employee-facing price per milligram-equivalent of morphine to plummet. We would strongly recommend revisiting your benefits design vis-à-vis opioid pricing.</p>,
        isCorrect: true,
        wasSelected: false                
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: null,
    harvardApproved: false    
  },
  // 9
  {
    questionText: "The typical employer spends ten to 100 times as much on prevention of heart attacks/diabetes as on prevention of opioid use disorders. By contrast, how does the overall cost of heart attacks and diabetes admissions (“wellness events”) compare to the cost of opioid use?",
    answers: [
      {
        answerText: "The total cost to an employer of wellness events vs. opioid use is in roughly the same ratio, 10-to-1, as the spending targeted at those respective health risks",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "A typical employer spends about the same on wellness-sensitive medical events as on opioid use disorders.",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "A typical employer is spending its education/prevention dollars in almost the exact opposite ratio as the spending on these two problems themselves",
        explanation: <p>CORRECT. There is a major misallocation of resources between attempting to prevent heart attacks and diabetes vs. attempting to prevent opioid abuse. Employers spend ten times as much on weight loss programs, health risk assessments and screenings as on opioid education and prevention – if indeed they spend anything at all on the latter. However, the total cost of opioids to a typical organization is estimated by Health Affairs, unfortunately behind a paywall, to be <a href='http://content.healthaffairs.org/content/35/5/824.abstract'>$756 PEPY whereas wellness events cost roughly a tenth that much</a>. According to the wellness trade association, the Health Enhancement Research Organization, only about 3 admissions per 1000, costing roughly $25,000 apiece, involve WSMEs – or $75 PEPY.</p>,
        isCorrect: true,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: null,
    harvardApproved: false    
  },
  // 10
  {
    questionText: "Which of these statements, if any, is FALSE?",
    answers: [
      {
        answerText: "Low-dose prescriptions for a long period are more likely to cause opioid use disorders than high-dose prescriptions for a short period.",
        explanation: <p>CORRECT. High doses, as a previous answer noted, can cause signs of an opioid use disorder within a few days. Low doses, over an extended period, are also problematic but <a href='https://www.ncbi.nlm.nih.gov/pubmed/27623005'>nowhere near as much</a>. (They also don’t provide the same level of relief and employees could be tempted to take more than needed.)</p>,
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "It costs more to treat an employee with opioid addiction than with diabetes.",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Only 3% of the total cost of opioids and opioid addiction is spent on treatment.",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "All are true.",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: null,
    harvardApproved: false    
  },
]

export default opioidsEmployer