import React from 'react'
import styled from 'styled-components'

const RED = '#FF5151'
const DARK_RED = '#D40707'
// const SUBMISSION_URL = 'http://localhost:9090/submit-demo'
const SUBMISSION_URL = 'https://quizzify-mailer.herokuapp.com/submit-demo'

const baseStyle = `
  background-color: white;
  width: 400px;
  font-size: 18px;
  padding: 5px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-family: avenir;
  max-width: 100%;
  border: 1px solid #d5d5d5;
`

const TextInput = styled.input`
  ${baseStyle}
`

const TextArea = styled.textarea`
  ${baseStyle}
  height: 75px;
`

const SubmitButton = styled.button`
  height: 50px;
  width: 400px;
  max-width: 100%;
  color: white;
  background-color: ${RED};
  border-radius: 5px;
  font-size: 20px;
  margin: 0;
  font-family: avenir;
  &:hover {
    background-color: ${DARK_RED};
  }
`

const Label = styled.p`
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 6px;
`

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class ContactUs extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  }

  render() {
    const {
      emailError,
      firstName,
      lastName,
      email,
      message,
    } = this.state

    const {
      onSuccessfulSubmit,
      onUnsuccessfulSubmit,
      quiz,
      submitText,
      type,
    } = this.props

    return (
      <React.Fragment>
        <div style={{ maxWidth: '100%', }}>
          <Label>FIRST NAME</Label>
          <TextInput
            required
            autoFocus
            placeholder="Enter First Name"
            type="text"
            name="firstName"
            onChange={e => this.setState({ firstName: e.target.value, })}
            value={firstName} />
          <Label>LAST NAME</Label>
          <TextInput
            required
            placeholder="Enter Last Name"
            type="text"
            name="lastName"
            onChange={e => this.setState({ lastName: e.target.value, })}
            value={lastName} />
          <Label>EMAIL</Label>
          <TextInput
            required
            placeholder="Enter work email"
            type="text"
            name="email"
            onChange={(e) => {
              const { value, } = e.target
              if (emailRegex.test(value)) {
                this.setState({ emailError: '', })
              }
              this.setState({ email: e.target.value, })
            }}
            value={email} />
          {emailError && <p style={{ color: 'red', marginTop: -10, marginBottom: 20, }}>{emailError}</p>}
          {!(type === 'conference') && (
            <React.Fragment>
              <Label>OPTIONAL MESSAGE</Label>
              <TextArea
                placeholder="Leave a message (optional)"
                onChange={e => this.setState({ message: e.target.value, })}
                value={message}
                type="text"
                name="message"
                size="10" />
            </React.Fragment>
          )}
        </div>
        <SubmitButton
          type="button"
          onClick={(e) => {
            if (!emailRegex.test(this.state.email)) {
              e.preventDefault()
              this.setState({ emailError: 'Please enter a valid email', })
            } else {
              fetch(SUBMISSION_URL, {
                method: 'POST',
                body: JSON.stringify({
                  firstName,
                  lastName,
                  email,
                  message: message,
                  quiz,
                }),
                headers:{
                  'Content-Type': 'application/json',
                }
              }).then((res) => {
                  onSuccessfulSubmit()
                })
                .catch((error) => {
                  onUnsuccessfulSubmit()
                  console.error('Error:', error)
                })
            }
          }}>
            {submitText || "REQUEST DEMO"}
          </SubmitButton>
      </React.Fragment>
    )
  }
}

export default ContactUs
