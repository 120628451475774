import React from 'react'
import styled from 'styled-components'

const RED = '#FF5151'
const DARK_RED = '#D40707'

const Message = styled.h2`
  font-family: avenir;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`

const SubmitButton = styled.button`
  height: 50px;
  width: 400px;
  color: white;
  background-color: ${RED};
  border-radius: 5px;
  font-size: 20px;
  margin: 0;
  font-family: avenir;
  &:hover {
    background-color: ${DARK_RED};
  }
`

const ThankYou = ({ isConference, }) => (
  <div
  className="appContainer"
  style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...(isConference ? {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    } : {})
  }}>
    <Message>
      Sorry! We could not save your information.
    </Message>
    <Message>
      Please try emailing us here:
    </Message>
    <a href="mailto:hello@quizzify.com?Subject=Request%20for%20demo&cc=quizzifydemocontactform@robot.zapier.com">
      <SubmitButton>
        Contact Us Directly
      </SubmitButton>
    </a>
  </div>
)

export default ThankYou
