export default [
  {
    questionText: 'Which are true about spray-on sunscreens?',
    answers: [
      {
        answerText: 'You\'re likely to miss, or maybe spray the dog by mistake — and waste a lot of it.',
        explanation: 'CORRECT. That stuff isn\'t cheap either.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'They pollute the ozone layer.',
        explanation: 'INCORRECT. Those of you with long memories may recall there is no such thing as "polluting" the ozone layer. It can only be "depleted" — and ozone-depleting chemicals are no longer used in consumer products.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'You probably shouldn\'t be breathing this stuff.',
        explanation: 'CORRECT. Really, no one knows. But why take the chance? Just use the regular rub-on version.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'It\'s not ideal, but hey, it\'s better than no sunblock.',
        explanation: 'CORRECT. But that\'s not exactly a great reason to use it.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'Don\'t spray it on your face.',
        explanation: 'CORRECT. You are likely to inhale it.',
        isCorrect: true,
        wasSelected: false,
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: 'http://www.nytimes.com/2016/05/28/travel/you-know-you-should-use-sunscreen-what-dont-you-know.html',
    harvardApproved:  true,
  },
  {
    questionText: 'What are the three most common mistakes when using sunscreen?',
    answers: [
      {
        answerText: 'Not reapplying every two hours.',
        explanation: 'CORRECT. Sunblock does need to be re-applied every two hours or so.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'Not reapplying "waterproof" sunblock after you get out of the water.',
        explanation: 'CORRECT. Even "waterproof" sunblock loses its effectiveness in the water, to some degree. You need to re-apply it.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'Putting on too much, and getting a Vitamin D deficiency as a result of insufficient sun exposure.',
        explanation: 'INCORRECT. Only a small amount of sun exposure is needed to avoid a Vitamin D deficiency. If you are concerned about Vitamin D, fortified dairy products and oily fish such as tuna or salmon are good choices. If you don\'t eat those foods and get very little sun, talk to a dietician or take small amounts of a supplement.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'Putting it on more than 15 minutes ahead of when you intend to go in the sun.',
        explanation: 'INCORRECT. You should apply sunblock in advance of going out because many don\'t reach full effectiveness for 15 minutes or so.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'Thinking that you don\'t need it because it\'s cloudy or cold.',
        explanation: 'CORRECT. It\'s still a good idea to put some on if it\'s cloudy. As for the temperature, here is the scoop: the angle of the sun in the sky, not the temperature, determines your sun exposure.',
        isCorrect: true,
        wasSelected: false,
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: 'http://www.nytimes.com/2016/05/28/travel/you-know-you-should-use-sunscreen-what-dont-you-know.html',
    harvardApproved: true
  },
  {
    questionText: 'A partner or a close friend is especially helpful in preventing which diseases or conditions?',
    answers: [
      {
        answerText: 'Premature death.',
        explanation: 'CORRECT. New research shows the importance not just of maintaining friendships, but of actually getting together with close friends. Loneliness shortens life expectancy, just like (though not as much as) smoking. This is especially true for men.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'Lyme Disease.',
        explanation: 'CORRECT. A partner or a close friend can use a magnifying glass to look for small ticks behind your knees, under your arms, and in places that a G-rated contest like Quizzify can\'t mention. Check daily if you walk in infested areas. Also, use tick repellant, and wear long pants tucked into your socks.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'Hypertension.',
        explanation: 'INCORRECT. Maintaining good relationships is good for health in general, though.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'Zika Virus.',
        explanation: 'INCORRECT. Friends are always good to have, but won\'t help here unless they swat a mosquito on you before it bites — and the mosquito happens to carry the virus (which fortunately is very rare in most states).',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'Uterine Cancer.',
        explanation: 'INCORRECT. There is no correlation.',
        isCorrect: false,
        wasSelected: false,
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: 'https://www.bostonglobe.com/magazine/2017/03/09/the-biggest-threat-facing-middle-age-men-isn-smoking-obesity-loneliness/k6saC9FnnHQCUbf5mJ8okL/story.html',
    harvardApproved: true,
  },
  {
    questionText: 'The two types of cancer most on the rise — and their causes — are...',
    answers: [
      {
        answerText: 'Lung cancer, caused by smoking.',
        explanation: 'INCORRECT. Lung cancer rates are following smoking rates downward. They are still VERY high. But they are trending in the right direction.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'Lung cancer, caused by radon (largely in people\'s basements).',
        explanation: 'INCORRECT. Radon is second only to smoking as a cause of lung cancer, and is growing, but not that fast. If you live in an area where radon is known to be present OR you live in an area where there is fracking, you should get a radon detector.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'Skin cancer caused by sun exposure.',
        explanation: 'CORRECT. Skin cancer is the most common cancer. It is also among the fastest-growing—but is largely preventable. Avoid tanning salons and don\'t skimp on the sunblock. Most people don\'t use enough.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'Cancer caused by radiation in CT scans, x-rays, etc.',
        explanation: 'CORRECT. Those CT scans are full of radiation. We\'re not saying: "Don\'t get scanned," but rather: "Weigh the benefit against the radiation, especially for your children." But don\'t be too alarmed. While this type of cancer is growing quickly, other cancers — like lung cancer — are far more common.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'Cancer of the ephogesium, caused by exposure to artificial preservatives and colors (such as Red Dye #2) in processed foods.',
        explanation: 'INCORRECT. There is no such organ as whatever we just said. Red Dye #2 has been banned for decades. And preservatives in the amounts we consume them don\'t cause cancer. Note: there IS such thing as an "esophagus." Cancers of those are also rising quickly, among people who take daily heartburn pills.',
        isCorrect: false,
        wasSelected: false,
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: 'http://www.cancer.gov/about-cancer/diagnosis-staging/ct-scans-fact-sheet',
    harvardApproved: true
  },
  {
    questionText: 'Which are true about poison ivy?',
    answers: [
      {
        answerText: 'Once you get the rash, it can spread.',
        explanation: 'INCORRECT. It doesn\'t "spread." It appears to spread only because the rash may erupt at different places on your skin at different times.	',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'You can get it from your clothes or your pets.',
        explanation: 'CORRECT. Wearing long pants and socks provides excellent protection...but only if you wash them afterwards. The oil can stick to clothes and still be potent later. Most pets are immune, but they can also get the oil on their skin.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'It is possible to prevent some or all of the rash by washing the exposed area soon after contact.',
        explanation: 'CORRECT. Waste no time. The more oil you get off your skin, the milder the rash will be. And the sooner you wash your skin, the more oil you will get off.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'There are some new treatments on the market that can make it go away much sooner.',
        explanation: 'INCORRECT. Mild cases have been treated the same way since before you were born, meaning ineffectively. More severe cases may require a doctor visit and prescription. See the link for treatment ideas.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'It can be a vine on a pole, not just a plant. (It does always have 3 shiny leaves, though.)',
        explanation: 'CORRECT. Poison ivy seems to love telephone poles for some reason. You should probably avoid contact with any leafy plant you are unsure of, just to be on the safe side. Poison oak, for example, has up to seven leaves. Sticking to paths should keep you safe from poison ivy, poison oak, ticks, and the occasional landshark.',
        isCorrect: true,
        wasSelected: false,
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: 'https://www.aad.org/public/diseases/itchy-skin/poison-ivy-oak-and-sumac#tips',
    harvardApproved: true
  },
  {
    questionText: 'You found an old sunscreen in the trunk of your 2015 Wagon Queen Family Truckster. Is it (the sunscreen) still good?',
    answers: [
      {
        answerText: 'Yes, sunscreen lasts 3 years and the car is nowhere near that old, so you\'re OK.',
        explanation: 'INCORRECT. Sunscreen should last 3 years but not if you leave it out where it gets hot.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'No, excess heat voids the expiration date.',
        explanation: 'CORRECT. Some sunscreens have an expiration date, but excess heat trumps that. Yes, you hate to toss it because it\'s expensive, but at this point it\'s also ineffective. (And, yes, that is the make and model of the car the Griswolds drove to Wallyworld.)',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'Yes, unless it has turned color.',
        explanation: 'INCORRECT. If it has turned color or the color is not uniform, then do toss it. But even if it hasn\'t turned color, don\'t use it if it\'s been in the heat possibly since 2015.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'Yes, but only if it\'s zinc oxide-based, since zinc is a mineral that lasts forever.',
        explanation: 'INCORRECT. Zinc lasts forever but zinc oxide doesn\'t. Toss sunblock that is three years old or that has been in the trunk for at least one full summer.',
        isCorrect: false,
        wasSelected: false,
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: 'http://www.consumerreports.org/cro/news/2014/05/does-sunscreen-expire/index.htm',
    harvardApproved: true
  },
  {
    questionText: 'Do white t-shirts prevent sun exposure?',
    answers: [
      {
        answerText: 'Only if they are dry, and you can\'t see through them.',
        explanation: 'CORRECT. A dry t-shirt has a sun protection factor (SPF) of about 7. This falls to about 3 if it gets wet. Old, threadbare t-shirts (and, yes, we love ours too) provide very little protection. You can still get sun damage or sunburns wearing them.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'Yes. They are a great "sunblock."',
        explanation: 'INCORRECT. Summer clothing is an OK sunscreen, but not a great sunblock. You need to buy specially treated clothes (or treat them yourself) to have them be a true sunblock.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'No. White t-shirts let all the skin-damaging rays through.',
        explanation: 'INCORRECT. They do prevent some sun from reaching your skin. Just not a lot.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'If you intend to be outside a lot wearing t-shirts, wash them using something called Sunguard.',
        explanation: 'CORRECT. That product, added to your laundry, increases the SPF of shirts to 20 or 25, like a sunblock.',
        isCorrect: true,
        wasSelected: false,
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: 'http://www.cnn.com/2012/07/10/living/guide-to-sun-safety/',
    harvardApproved: true
  },
  {
    questionText: 'Which of the following is true about expensive sunscreens?',
    answers: [
      {
        answerText: 'They offer much more protection than the inexpensive ones.',
        explanation: 'INCORRECT. If there is one place you can save money, it\'s on sunblock. Prices range from $0.65 to $24 per ounce. Look for "waterproof" and "sweatproof" if you intend to go in the water or sweat. Otherwise, "broad-spectrum" and "SPF 30" should be fine.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'They may offer less protection than the inexpensive ones.',
        explanation: 'CORRECT. The expensive ones feel good going on. That\'s what you\'re paying for, not extra protection. See the link.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'People have been observed using less sunblock than they should, because of the high price tags.',
        explanation: 'CORRECT. Like anything, the more it costs, the less you buy — or in this case, the less you use. It\'s human nature. Better to buy an inexpensive brand that is broad-spectrum and has an SPF of 30 and slather it on than to buy a pricey brand and use a tiny amount.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'Their major benefits are coming in fancier containers and feeling smoother when you put them on your skin.',
        explanation: 'CORRECT. Read the label carefully. Look first for some version of "waterproof" or "sweatproof" — and then "broad-spectrum" and "SPF 30." (And read the link!)',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'There is an organization which obsesses over collecting data about sunblock testing — and it doesn\'t find the expensive ones to be better.',
        explanation: 'CORRECT. It\'s called the Environmental Working Group and, even though some of their agenda is political and controversial, it does collect more data on sunblock than anyone else. See the link.',
        isCorrect: true,
        wasSelected: false,
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: 'https://www.ewg.org/sunscreen/',
    harvardApproved: true
  },
  {
    questionText: 'Which are true about tick bites?',
    answers: [
      {
        answerText: 'If you have a bullseye-shaped rash, you likely have Lyme Disease — but it\'s early-stage and hence more treatable than late-stage.',
        explanation: 'CORRECT. No other disease presents this way. See the link.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'If you were bitten by a tick but don\'t see a bullseye rash within two weeks, you\'re fine.',
        explanation: 'INCORRECT. Lyme Disease doesn\'t always present with a bullseye rash, and there are other tick-borne illnesses. Basically, if you have flu-like symptoms in the summer, you should visit a doctor and ask specifically to be tested for tick-borne illnesses.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'If you see a tick in you, you should save the actual tick itself once you pry it off (from below, not squeezing its body).',
        explanation: 'CORRECT. Save the tick! Sometimes testing the tick is the best way to determine if you have a tick-borne illness.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'Actual symptoms may take a long time to develop.',
        explanation: 'CORRECT. Rash or no rash, it may take a long time before you feel bad, and "feeling bad" comes in many different ways. See the link.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'Even if you have a bullseye rash, you may be diagnosed as not having Lyme Disease.',
        explanation: 'CORRECT. Lyme Disease is very hard to diagnose, and is often missed. So if you "test negative" (meaning no Lyme Disease) but you continue to have unexplained symptoms, ask for a re-test. This is why you should save the tick!',
        isCorrect: true,
        wasSelected: false,
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: 'http://www.mayoclinic.org/diseases-conditions/lyme-disease/basics/symptoms/con-20019701',
    harvardApproved: true
  },
  {
    questionText: 'Are tanning salons a "safe" way to get a suntan?',
    answers: [
      {
        answerText: 'Yes. They only have UVA rays, not the UVB rays that burn.',
        explanation: 'INCORRECT. Any kind of UV ray is bad for your skin.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'Yes. Assuming you want to get a tan, they are safer than the sun and than self-tanning lotions, which can cause skin cancer.',
        explanation: 'INCORRECT. See the link. Indoor tanning is considered as unsafe as outdoor tanning. But self-tanning lotions have never been shown to cause any real negative effects, other than looking just slightly more orange than humans are supposed to look.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'Yes, but only for short periods.',
        explanation: 'INCORRECT. Tanning bed rays are intense. Even a short session will age your skin and increase your risk of skin cancer.',
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: 'No. The rays are more intense than outdoor sun in most US areas.',
        explanation: 'CORRECT. The rays are more intense. That\'s why you can get a tan in a short time.',
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: 'No. Indoor tanning is definitely linked to cancer.',
        explanation: 'CORRECT. See the link.',
        isCorrect: true,
        wasSelected: false,
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: 'http://www.cdc.gov/cancer/skin/basic_info/indoor_tanning.htm',
    harvardApproved: true
  },
]