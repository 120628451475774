import React from 'react'
import PropTypes from 'prop-types'

const basicStyle = {
  color: 'white', fontFamily: 'avenir', width: '100%', textAlign: 'center',
}

const Background = ({ title, subtitle, children, }) => (
  <div style={{ backgroundColor: '#479EF8', minHeight: '100vh', height: 'auto', minWidth: '100vw', }}>
    {title && 
      <h1 style={{ ...basicStyle, fontFamily: 'didot', marginBottom: 10, marginTop: 0, paddingTop: 20, fontSize: 50, }}>
        {title}
      </h1>
    }
    {subtitle &&
      <h2 style={{
        ...basicStyle,
        marginTop: 20,
        marginBottom: 25,
        fontSize: 30,
        fontWeight: 400,
      }}>
        {subtitle}
      </h2>
    }
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 25, }}>
      {children}
    </div>
  </div>
)

Background.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

Background.defaultProps = {
  title: 'Quizzify',
}

export default Background