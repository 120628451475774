import React from 'react'
import '../App.css'

class Answer extends React.Component {
  render() {
    const answerDictionary = this.props.answerDictionary
    var answerText = answerDictionary.answerText
    const explanation = answerDictionary.explanation
    const isCorrect = answerDictionary.isCorrect
    const linkAddress = this.props.linkAddress
    
    var questionLink
    if (linkAddress) {
      questionLink = <p className="answerDetail"><a href={linkAddress} target="_blank" rel="noopener noreferrer">Learn More</a></p>
    } 

    var pointsChange = "";
    if (this.props.pointsChange > 0) {
      pointsChange = " +" + this.props.pointsChange + " points. This question is now complete. Review or keep going."
    } else if (this.props.pointsChange < 0) {
      pointsChange = " " + this.props.pointsChange + " points"
    } 

    var indicatorText;
    if (isCorrect) {
      indicatorText = <p className="answerDetail correctAnswer">{explanation}<strong>{pointsChange}</strong></p>
    } else {
      indicatorText = <p className="answerDetail incorrectAnswer">{explanation}<strong>{pointsChange}</strong></p>
    }

    if (this.props.isHighlighted === this.props.arrayPositionIndex) { // arrayIndex 
      return (
        <div ref={element => this.answer = element} className="listItemContainer">
          <li>
            {getAnswerIndicator(isCorrect)}
            {answerText}
            {indicatorText}
            {questionLink}
          </li>
        </div>
      )
    } else if (answerDictionary.wasSelected) {
      return (
        <div ref={element => this.answer = element} className="listItemContainer">
          <li onClick={() => {
            this.props.onClick()
          }}>
            {getAnswerIndicator(isCorrect)}
            {answerText}
          </li>
        </div>
      )
    } else {
      return (
        <div ref={element => this.answer = element} className="listItemContainer">
          <li onClick={() => {
            this.props.onClick()
          }}>
            {answerText}
          </li>
        </div>
      )
    }
  }
}

export default Answer

const getAnswerIndicator = answerIsCorrect => (
  answerIsCorrect ?
    <p style={{ color: '#138a13', float: 'right', margin: 0, fontSize: 16, }}>✔</p> :
    <p style={{ color: '#bd0101', float: 'right', margin: 0, fontSize: 16, }}>✘</p>
)
