import styled from 'styled-components'

const green = '#138a13'

const Button = styled.button`
  width: 150px;
  height: 40px;
  font-size: 14px;
  text-transform: uppercase;
  background-color: white;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  color: ${props => props.disabled ? 'gray' : green};
  border: solid 1px ${props => props.disabled ? 'gray' : green};
  border-radius: 6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  &:hover {
    background-color: ${props => props.disabled ? 'white' : green};
    color: ${props => props.disabled ? 'gray' : 'white'};
  }
  &:focus {
    outline: none;
  }
`

export default Button
