import React from 'react'
import '../../App.css'
import Shield from './Shield'

const Question = ({ questionDictionary, }) => {
  const { questionText, harvardApproved, } = questionDictionary

  return (
    <div>
      <Shield visible={harvardApproved} />
      <div className="questionContainer">
        <h1 className="questionText">{questionText}</h1>
      </div>
    </div>
  )
}

export default Question
