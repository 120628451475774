import React from 'react'
import Button from './Button'

const PreviousButton = ({ onClickPrevious, }) => (
  <Button onClick={onClickPrevious} style={{ marginLeft: 8, marginRight: 8, }}>
    Previous
  </Button>
)

export default PreviousButton
