import React from 'react'

const Container = ({ children, }) => (
  <div
    id="container"
    style={{
      width: '90%',
      height: '90%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingBottom: 50,
      flexWrap: 'wrap',
    }}>
    {children}
  </div>
)

export default Container
