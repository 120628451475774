const aarpEeoc = [
  {
    questionText: "According to the ruling in the case, which of the following is true come January 2019?",
    ssid: 427,
    answers: [
      {
        answerText: "You are not allowed to use penalties but incentives are OK.",
        explanation: "INCORRECT. The Court did not distinguish between the two.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Wellness programs may not involve large penalties or incentives.",
        explanation: "INCORRECT. Only clinically oriented wellness programs like screens or health risk assessments are affected at all by this decision.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "You are not allowed to do clinically oriented wellness, like screenings or health risk assessments, that have large incentives or penalties.",
        explanation: "INCORRECT. You are allowed to do these programs with large incentives or penalties, but there is no safe harbor. If you don’t also offer a Quizzify-type option or other non-clinical option for employees to earn all the credit, an employee could sue and the case would (until precedent is established or rules are published) likely get to court. Whether the plaintiff prevails or not is an open issue. So these are allowable, but risky.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText:
        "None of the above.",
        explanation: "CORRECT. Clinically oriented wellness is still perfectly legal (meaning no criminal penalties would be assessed), with whatever incentives you choose. However, your exposure to a lawsuit is higher, the higher the penalty or incentive. No one yet knows what the financial incentive/penalty cutoff point for “voluntary” programs will be",
        isCorrect: true,
        wasSelected: false            
      },
      
      
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://theincidentaleconomist.com/wordpress/vacating-an-eeoc-rule-on-wellness-programs/",
    harvardApproved: false 
  },
  // 2
  {
    ssid: 535,
    questionText: "What happens to programs with typical incentives or penalties starting in January?",
    answers: [
      {
        answerText: "Assuming you don’t offer an alternative like Quizzify or certain exercise programs involving no weight or other clinical measurement, you are much more exposed to employee lawsuits than you are today.",
        explanation: "CORRECT. Absent a non-clinical alternative, typical programs are not voluntary due to the large financial forfeitures incurred by non-participants or employees who don’t satisfy the outcomes goals. The court says these programs must be voluntary. Therefore, an employee could sue in 2019 with a much greater likelihood of prevailing than in 2018.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Your in-house counsel should advise you to cut way back on incentives or penalties, unless you offer a non-clinical alternative.",
        explanation: "CORRECT. As Bravo Wellness’s attorneys put it, heavily incentivized or penalized clinically oriented programs may not be looked upon favorably by employers with a conservative risk profile. (That would be you.)",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "There are no rules defining the word ”voluntary.” It is up to the courts to define it.",
        explanation: "CORRECT. The judge in this case implied that courts should look for something much closer to the dictionary definition than to the current EEOC rules.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "The EEOC will likely move for and be granted an extension on the old rules while they promulgate new rules.",
        explanation: "INCORRECT. That is exactly the opposite of the ruling. The old rules are gone whether or not there are new rules. AARP had asked for 2018 to be the starting point. Giving EEOC to 2019 was itself the ”extension.”",
        isCorrect: false,
        wasSelected: false
      },
      
      
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.benefitnews.com/opinion/surprising-court-decision-may-outlaw-most-wellness-incentives",
    harvardApproved: false    
  },
  // 3
  {
    questionText: "What factors suggest employee lawsuits, though possible, are unlikely?",
    ssid: 553,
    answers: [
      {
        answerText: "Employees have been unsuccessful in anti-wellness lawsuits in the past, which creates a fairly definitive precedent.",
        explanation: "INCORRECT. That is true, but the AARP v. EEOC decision trumps the precedents in earlier cases.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "The majority of employees like wellness.",
        explanation: "INCORRECT. Many or most are fine with or supportive of lunch-and-learns, gym membership or other fitness programs -- or Quizzify, whose excellent outcomes are linked to this question. However, a WillisTowersWatson survey found that the workplace wellness industry has by far the lowest ”Net Promoter Score” of any industry.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "They are concerned about being fired.",
        explanation: "INCORRECT. Quite the opposite. The EEOC provides strong anti-retaliation protection. An employee who thinks he or she could be laid off might actually be increasing job security by suing. A heavily incentivized wellness program would be a great excuse.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "The law is against them.",
        explanation: "INCORRECT. The law was against them prior to AARP v. EEOC. Absent rules, different federal judges may see cases differently, until precedent is established or rules are published.",
        isCorrect: false,
        wasSelected: false                
      },
      {
        answerText: "None of the above.",
        explanation: "CORRECT. By sheer coincidence, we already know of two employees planning lawsuits. It’s unlikely, statistically speaking, that come January they will be the only ones.",
        isCorrect: true,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.quizzify.com/single-post/2018/01/18/The-Vote-Are-In",
    harvardApproved: false    
  },
  // 4
  {
    ssid: 556,
    questionText: "Medical inquiries are not in the safe harbor starting in January. Which two of the following questions will most likely be in the safe harbor for health risk assessments starting in 2019?",
    answers: [
      {
        answerText: "Are you comfortable with your weight?",
        explanation: "CORRECT. While somewhere a court might feel differently so we can’t be totally sure, this would be one of the three safest questions on this list.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "How much do you weigh?",
        explanation: "INCORRECT. This would count as a medical inquiry.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Do you smoke?",
        explanation: "CORRECT. It has been established that smoking is not a disability, so inquiring about smoking is not a medical inquiry under the ADA. Unfortunately, most employees who smoke lie about it on HRAs, so asking employees is a waste of time. For instance, in the Koop Award-winning Boise School District wellness program, only 3% of employees admitted to smoking, in a state with a 19% smoking rate. However, you will most likely not be in the safe harbor if you do actual nicotine or cotinine tests, as those would appear to constitute medical exams.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Do you often feel down in the dumps?",
        explanation: "INCORRECT. While it is possible that this would not be considered a medical inquiry, it is not one of the safest two questions. A court could view this question as a stealthy way of asking whether an employee is depressed, which is a medical inquiry.",
        isCorrect: false,
        wasSelected: false                
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: 'http://disabilityrightspa.org/File/publications/disability-related-inquiries-and-medical-examinations-of-employees-and-applicants.pdf',
    harvardApproved: false    
  },
  // 5
  {
    ssid: 548,
    questionText:"Which are true about corporate crash-dieting contests with rewards?",
    answers: [
      {
        answerText: "They are more likely to harm employees than benefit them.",
        explanation: "CORRECT. Weight-cycling, also called yo-yo dieting, is believed but not totally proven to be a bad idea. On the flip side, it rarely succeeds, and many employees will regain more weight than they lose in the crash-dieting contest. Rewarding employees for weight-cycling adds the likelihood of binge-eating before the initial weigh-in, which would exacerbate harms or potentially create harms where none now exist.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "They are technically already out of compliance with the Affordable Care Act.",
        explanation: "CORRECT. First, no ”reasonable alternative standard” is provided for employees who can’t or shouldn’t lose weight. Second, these programs are not ”reasonably designed to reduce risk or prevent disease”. Quite the opposite, when rewards are involved, they make health issues more likely.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Because they are voluntary, they will still be in a safe harbor come January.",
        explanation: "INCORRECT. Employees are given a choice: be weighed and save money on insurance, or don’t be weighed and have no chance of saving money. That is a medical exam that penalizes people who don’t take it. The only way this gets safe harbor status is if weight is not related to insurance or you offer something else of equal reward potential to employees who don’t want to crash-diet. (Yes, Quizzify qualifies.)",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "All of the above.",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false                
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.benefitnews.com/opinion/why-employers-should-ditch-biggest-loser-contests",
    harvardApproved: false    
  },
  // 6
  {
    ssid: 555,
    questionText: "What is the difference, if any, between the reasonable alternative standard required by ACA for employees who don’t want to do the wellness program, and offering a safe-harbor reasonable alternative under ADA (and GINA), starting in January?",
    answers: [
      {
        answerText: "The reasonable alternative standard under ACA, if you adhere to it conscientiously, will keep you in a safe harbor in 2019.",
        explanation: "INCORRECT. ACA and ADA are two very different laws. As EEOC has said, they are not “harmonized”.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "An employee must petition for the reasonable alternative standard, and you can deny it.",
        explanation: "CORRECT. By contrast, to be in the safe harbor in 2019 (and to qualify for Quizzify’s indemnification), a non-clinical alternative, such as Quizzify, must be clearly offered to any taker, who may choose it just based on personal preference. Their call, not yours. That by itself makes the biometric screening, health risk assessment, or other medical exam/inquiry truly voluntary, regardless of the incentive or penalty.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "The reasonable alternative standard is still usually a medical exam or inquiry. A safe-harbor reasonable alternative cannot be.",
        explanation: "CORRECT. A few companies offer a Quizzify-type reasonable alternative to employees who petition, but most employees successfully petitioning under this standard are given a clinical alternative. However, a Quizzify-type or exercise-type alternative, offered to any employee, is a necessity in order to be in the safe harbor of having a truly voluntary medical exam.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "No difference.",
        explanation: "INCORRECT. The two types of alternatives are completely independent of each other, under different statutes. However, offering Quizzify to all comers does satisfy both, and will save a great deal of money immediately. In addition, Quizzify has a much better chance of educating employees in how to purchase medical care wisely and how to stay healthy, as the linked results show.",
        isCorrect: false,
        wasSelected: false                
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.quizzify.com/single-post/2018/01/18/The-Vote-Are-In",
    harvardApproved: false    
  },
  // 7
  {
    ssid: 551,
    questionText: "Which of the following can be said with the greatest confidence about annual medical exams?",
    answers: [
      {
        answerText: "They are highly recommended by most experts,",
        explanation: "INCORRECT. The New England Journal of Medicine, the Journal of the American Medical Association, Consumer Reports, Choosing Wisely and the US Preventive Services Task Force do not recommend annual physicals, finding on balance no net benefits to a population in excess of harms. However, employees should still be educated to recognize unfamiliar symptoms, such as lethargy, swollen ankles, suspicious moles, change in bowel habits, weight or mood etc., and seek medical care accordingly. If indeed they have not had a checkup, this would be a good excuse to get one free. Americans both overconsume and underconsume primary care. Quizzify educates employees both on when to seek medical care, tests, and procedures…and when not to.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Starting in 2019, unless the rules state otherwise, checkups will count as medical exams and be outside the safe harbor if employees are paid/penalized, and if the results need to be submitted somewhere.",
        explanation: "CORRECT. It is also possible that medical exams where the only documentation needed is the fact that it took place will be outside the safe harbor. This is not knowable yet.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "They will be perfectly fine in 2019, with whatever incentive/penalty you choose, as long as you don’t require the results to be submitted somewhere, or require certain outcomes to be attained and documented.",
        explanation: "INCORRECT. The ADA states ”a requirement of a medical checkup.” It is possible that a court would be looking hard at the first word, regardless of whether the results were shared or not. No one knows…yet.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "All of the above.",
        explanation: "INCORRECT. ",
        isCorrect: false,
        wasSelected: false                
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "http://www.newsweek.com/cdc-opiate-addiction-572498",
    harvardApproved: false    
  },
  // 8
  {
    ssid: 549,
    questionText: "Though nothing can be said for sure, which two of the following are the one most likely to be OUTSIDE the safe harbor (meaning legally risky) starting in January?",
    answers: [
      {
        answerText: "Crash-dieting contests against other companies, but with rewards only for the winning company.",
        explanation: "CORRECT. This is still an inducement and there is an expected value of winning. This is also a bad idea, for the health of the employees. It encourages binging and, of course, crash-dieting",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Flu shots or shingles vaccines where the employer covers the co-pay.",
        explanation: "INCORRECT. There is likely no finding of a financial inducement, and these aren’t medical exams. The shingles co-pay would be covered anyway.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Discounts for smokers or premiums for non-smokers.",
        explanation: "INCORRECT. These are OK, though employees will have to be on the honor system. (Good luck with that.)",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Giving out points for things like extra paid time off, instead of a financial penalty or incentive.",
        explanation: "CORRECT. This is a close one, but of the four things on this list, this is the second-most likely to be outside the safe harbor. Paid time off has value, and so-called points only have value if they can be exchanged for something with monetary value.",
        isCorrect: true,
        wasSelected: false                
      },
      
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://blog.myshortlister.com/2018/02/01/aarp-vs-eeoc-on-the-contrary-it-is-almost-time-to-panic?utm_source=hs_email&utm_medium=email&utm_content=60413277&_hsenc=p2ANqtz-8wvBKvevzJzeXOulkWvc8h0-d1cN5aO8gW06Uyf2OgFmTX-kG8f0jci8nRxr-Lfpf75OzwMe40jRzwSDGcT27IVb1dpw&_hsmi=60413177",
    harvardApproved: false    
  },
  // 9
  {
    ssid: 342,
    questionText: "Which will be true about exercise programs that carry an incentive or a penalty?",
    answers: [
      {
        answerText: "They are all subject to the same potential sanctions as every other wellness program that involves a similar payout.",
        explanation: "INCORRECT. Unless you are measuring clinical outcomes, like weight loss or a stress test, these would not be subject to the same pitfalls we have been discussing in this quiz. There are certainly other rules, but AARP v. EEOC does not change the treatment of fitness programs per se.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "It depends on whether you let employees self-report how much they exercise, or make them wear a fitbit or something similar to record their exercise.",
        explanation: "INCORRECT. As long as the outcomes are not clinical in nature, employees can self-report or you can track them. Tracking or asking how much exercise an employee does is not considered a medical inquiry.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "They are fine even with fitbits, as long as you don’t incentivize or measure clinical outcomes, like weight loss.",
        explanation: "CORRECT. The key is not much you measure, or how intrusive your measurement is, or how much money is at stake. It is whether the measurement in question is clinical.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "They are only fine if an employee is allowed to take off their fitbit from time to time, as opposed to having all their activity tracked..",
        explanation: "INCORRECT. Hopefully you are not tracking their every move, but AARP v. EEOC does not constrain your current ability to do that.",
        isCorrect: false,
        wasSelected: false
      },
      
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.benefitnews.com/opinion/surprising-court-decision-may-outlaw-most-wellness-incentives",
    harvardApproved: false    
  },
  // 10
  {
    questionText: "Which are true about Quizzify’s indemnification for employers and partners surrounding relevant employee plaintiff legal actions?",
    ssid: 528,  
    answers: [
      {
        answerText: "Quizzify is the only vendor that does this, and no broker or consultant recommends it.",
        explanation: "INCORRECT. Switchbridge, Sterling Wellness, It Starts with Me, and Sustainable Health Index all indemnify, while GCG Financial (an Alera Group company) and Connect Healthcare Collaboration procure programs that indemnify employers.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "The major objection Quizzify receives is that it sounds too good to be true.",
        explanation: "CORRECT. While AARP v. EEOC is a lemon for most vendors and employers, it is a pitcher of lemonade for Quizzify, its customers and its partners. Quizzify’s founders designed the product knowing this decision would happen someday. As a result it is totally compliant, and by being offered equally to employees, makes the ”medical exam or inquiry” of a screening or a risk assessment truly voluntary, no matter what your incentive or penalty is. The indemnification means you don’t have to take our word for this.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "It covers the entire wellness program, not just Quizzify.",
        explanation: "CORRECT. Your program can remain exactly as it is today, as long as 100% of the points or other financial inducement can be achieved without medical exams or inquiries. Quizzify will indemnify 80% of any judgment up to ten times our fees following a $5000 per-case deductible.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Quizzify also costs less but is more effective and more engaging than your current wellness program.",
        explanation: "CORRECT. No need to take our word for that either. See the linked guarantees. Or just play the sample game.",
        isCorrect: true,
        wasSelected: false            
      },    
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.quizzify.com/guarantees", 
    harvardApproved: false
  }
]

export default aarpEeoc