import styled from 'styled-components'
import {
  RED,
  DARK_RED,
} from './Constants'

const Button = styled.div`
  background-color: ${RED};
  padding: 15px;
  color: white;
  max-width: 75%;
  width: 250px;
  text-align: center;
  font-size: 30px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  letter-spacing: 1.5px;
  &:hover {
    background-color: ${DARK_RED};
  }
`

export default Button