import React from 'react'
import styled from 'styled-components'
import centerMobile from '../mediaQueries'

const Container = styled.div`
  position: absolute;
  margin-top: 10px;
  padding: 8px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: #0355A4;
  width: 250px;
  text-align: center;
  background-color: white;
  right: 10px;
  z-index: 2;
  ${centerMobile.small} {
    ${props => props.centerMobile ? 'right: auto' : 'right: 0'}
  }
`

const Tooltip = ({ text, style, centerMobile, }) => (
  <Container style={style} centerMobile={centerMobile}>
    {text}
  </Container>
)

export default Tooltip
