const opioidsEmployee = [
  // 1
  {
    questionText: "What are some major risks of taking ibuprofen (Advil) regularly?",
    ssid: 427,
    answers: [
      {
        answerText: "Constipation.",
        explanation: "INCORRECT. This is a risk of prescription painkillers, which are much less safe than Advil.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Kidney problems.",
        explanation: "CORRECT. Kidney function should be checked regularly if you taking Advil (ibuprofen) every day. And if you experience kidney pain, stop taking it until you see or talk to your doctor. (Even so, ibuprofen is much safer than prescription painkillers.)",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Stomach ulcers.",
        explanation: "CORRECT. Read the link. Take it with food to help avoid stomach irritation. See your doctor if you have significant pain (and stop taking it). If your stools (BMs) turn very dark, that also means you have stomach problems. Even with this risk, these drugs are MUCH safer than prescription painkillers.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText:
        "Bleeding more if you have an accident.",
        explanation: 
        "CORRECT. It can interfere with appropriate blood clotting, which is usually not a problem -- unless you have an accident. However, none of us plan to have an accident, so keep this in mind when taking Advil regularly.",
        isCorrect: true,
        wasSelected: false            
      },
      {
        answerText: "Flatulence.",
        explanation: "INCORRECT. You can't blame the Advil, so blame the dog. That's our strategy here at Quizzify.",
        isCorrect: false,
        wasSelected: false            
      }
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "http://www.webmd.com/drug-medication/otc-pain-relief-10/pain-relievers-nsaids",
    harvardApproved: true 
  },
  // 2
  {
    ssid: 535,
    questionText: "Got chronic back pain? Which of the following aren't helping?",
    answers: [
      {
        answerText: "Shoulder bags.",
        explanation: "CORRECT. There is a tendency to load too much into them. Water bottles are especially heavy. See the link for more lightening-up ideas.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "High heels.",
        explanation: "CORRECT. Unless you have weak ankles or osteoporosis, try platform shoes instead of heels. See the link for more ideas.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Cellphones.",
        explanation: "CORRECT. Look at yourself in the mirror as you talk. Does that look like a healthy posture? We didn't think so. Try using a cellphone earpiece. They don't look like they could work since they don't even come close to your mouth, but they do.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Getting up and down from your seat.",
        explanation: "INCORRECT. It's actually a good thing to move around. Our tendency is to stay glued to our screens. That's a bad idea for both your back and your eyes.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Taking painkillers.",
        explanation: 
        "CORRECT: Long-term users of painkillers show much worse outcomes than people with equal pain on the \"pain scale\" who manage without them.",
        isCorrect: true,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.spineuniverse.com/wellness/ergonomics/how-fashion-ergonomics-helps-your-spine",
    harvardApproved: false    
  },
  // 3
  {
    questionText: "Which are true about getting your (or your kids') wisdom teeth out?",
    ssid: 553,
    answers: [
      {
        answerText: "If nobody got their wisdom teeth out, oral surgeons would make 80% less money.",
        explanation: "CORRECT. We're not saying yours is misleading you, but there is a strong incentive for them to keep pulling out teeth.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "There is no reason to take out wisdom teeth that aren't causing a problem.",
        explanation: "CORRECT. See the link. Maybe they could cause a problem down the road. In that case, you can take them out -- down the road. There is probably no operation in all of health care done more often, with more discomfort at more expense...with less evidence supporting it.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Most people don't use all the painkillers they are prescribed.",
        explanation: "CORRECT. But they don't throw them out either...and that creates one of the major opportunities for painkiller misuse by the patient or family. Chuck 'em!",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Get a second opinion from a dentist in another practice.",
        explanation: "CORRECT. A lot of dental procedures can be avoided with second opinions. But not from a dentist in the same practice, who is unlikely to contradict the first dentist.",
        isCorrect: true,
        wasSelected: false                
      },
      {
        answerText: "They are called \"wisdom teeth\" for a reason. About 1 in 100 patients will suffer a decline in IQ as a result of this extraction.",
        explanation: "INCORRECT. They are indeed called \"wisdom teeth\" for a reason. But that's not it. It's because they grow in after kids have become older and presumably wiser.",
        isCorrect: false,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "http://www.npr.org/sections/health-shots/2017/02/26/513865623/dentists-work-to-ease-patients-pain-with-fewer-opioids",
    harvardApproved: false    
  },
  // 4
  {
    ssid: 556,
    questionText: "Which are the two major reasons teens use more addictive prescription drugs than ever before?",
    answers: [
      {
        answerText: "They can get them easily from friends or relatives.",
        explanation: "CORRECT. More than two-thirds are obtained that way. That means you, as parents, need to lock them up (the drugs, not the kids).",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "More than half are prescribed to them by doctors and are usually covered by insurance.",
        explanation: "INCORRECT. While some doctors will do this, most are wary about over-prescribing addcitive drugs to teens.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Because they are legal, the local schoolyard drug dealer can accept credit cards.",
        explanation: "INCORRECT. They don't run Groupons either.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "They have the desired impact like illegal drugs do, but teens believe them to be safe because they are legal.",
        explanation: "CORRECT. Potency, perceived safety, and ease of availability are a potentially deadly combination for abuse.",
        isCorrect: true,
        wasSelected: false                
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: 'https://drugfree.org/wp-content/uploads/2015/04/Matrix_OpioidAbuse_040415.pdf',
    harvardApproved: false    
  },
  // 5
  {
    ssid: 548,
    questionText:
    "What is most likely to give you an addiction, if you do it once?",
    answers: [
      {
        answerText: "One 10-day prescription for painkillers.",
        explanation: "CORRECT. 20% of people who get a 10-day prescription end up addicted -- as do 6% of people with a prescription for a single day!",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "One 30-day prescription for Ambien.",
        explanation: "INCORRECT. People most at risk for Ambien addiction are people who have other substance abuse issues. Others seem to be at low risk.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "One marijuana cigarette.",
        explanation: "INCORRECT. We're not wading into the marijuana debate in general (we want to keep this gig!) but it is absolutely the case that one \"joint\" will not create addiction.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "All of these are highly addictive.",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false                
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://arstechnica.com/science/2017/03/with-a-10-day-supply-of-opioids-1-in-5-become-long-term-users/",
    harvardApproved: false    
  },
  // 6
  {
    ssid: 555,
    questionText: "Which is the easiest to become addicted to, once you start?",
    answers: [
      {
        answerText: "Nicotine (vaping, smoking, chewing)",
        explanation: "INCORRECT. We're not exactly recommending it, but it's not \"the easiest thing.\"",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Prescription pain medication.",
        explanation: "CORRECT. It can be addictive in a matter of days. Prescription painkiller abuse is the fastest-growing cause of death in the US.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Alcohol.",
        explanation: "INCORRECT. Plenty of people drink responsibly. By definition, it is not possible to take illegal addictive drugs responsibly. That would be like saying it is possible to drive drunk responsibly.  ",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Marijuana.",
        explanation: "INCORRECT. Marijuana is not \"addictive\" in the sense that if you stop using it, you don't experience withdrawal symptoms, as with real addictions.",
        isCorrect: false,
        wasSelected: false                
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "http://www.newsweek.com/cdc-opiate-addiction-572498",
    harvardApproved: false    
  },
  // 7
  {
    ssid: 551,
    questionText: "When taking prescribed doses of painkillers, measurable indicators of addiction can begin in:",
    answers: [
      {
        answerText: "3 hours",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "3 days",
        explanation: "CORRECT. See the link.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "3 weeks",
        explanation: "INCORRECT. 20% of people taking painkillers in the typically prescribed dosage for 3 weeks are addicted.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "3 months",
        explanation: "INCORRECT. The majority of people taking painkillers for 3 months or more are addicted.",
        isCorrect: false,
        wasSelected: false                
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "http://www.newsweek.com/cdc-opiate-addiction-572498",
    harvardApproved: false    
  },
  // 8
  {
    ssid: 549,
    questionText: "Three big short-term problems with taking prescription pain medication include:",
    answers: [
      {
        answerText: "Constipation.",
        explanation: "CORRECT. These pills slow down movement through your intestines. Do you really want that stuff inside you any longer than necessary? You are putting your long-term digestive health at risk.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Addiction.",
        explanation: "CORRECT. Out of five people taking a ten-day prescription for pain medication as directed, one person will end up addicted.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Death",
        explanation: "INCORRECT. Lethal overdoses of prescription medicine are a major risk -- but not for the first prescription.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Drowsiness, lethargy and slowed reaction times.",
        explanation: "CORRECT. You should probably not drive soon after taking a prescription painkiller. It increases your risk of accident and in some states you may be cited for driving under the influence (DUI).",
        isCorrect: true,
        wasSelected: false                
      },
      {
        answerText: "Bad breath.",
        explanation: "INCORRECT. If you experience more trouble finding dates when you're taking painkillers, it's likely because of your addiction, not your breath. You can eventually get bad breath from opioids because they dry out your mouth, but it takes regular use over a long period to make that happen.",
        isCorrect: false,
        wasSelected: false                
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.news-medical.net/health/Opioid-Side-Effects.aspx",
    harvardApproved: false    
  },
  // 9
  {
    ssid: 342,
    questionText: "Four major long-term problems caused by prescription pain medicine include:",
    answers: [
      {
        answerText: "Cavities.",
        explanation: "CORRECT. Opioids dry out your mouth, which sets you up for cavities and gum disease. It is important to keep your mouth moist, using ice chips and/or a humidifier, and a dry-mouth mouthwash, like Biotene.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Intestinal disease.",
        explanation: "CORRECT. These medicines interfere with your intestines. Long-term constipation can set you up for a lifetime of intestinal problems.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Needing more and more for the same pain relief.",
        explanation: "CORRECT. Needing more and more of a drug to produce the same effect is called \"tolerance,\" which is like addiction. And the more you take, the more likely you will experience the other side effects.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Nausea, vomiting and diarrhea.",
        explanation: "CORRECT. These drugs wreak havoc on your digestive system from top to bottom.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Toenail fungus",
        explanation: "INCORRECT. It took us a while to find something that isn't a possible long-term effect. Back story: \"Cavities\" was supposed to be the gag answer until we learned you can get cavities and gum disease because opioids cause dry mouth, which itself causes gum and teeth problems.",
        isCorrect: false,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.health.harvard.edu/blog/report-highlights-dangers-opioid-painkillers-201501157615",
    harvardApproved: true    
  },
  // 10
  {
    questionText: "What is the leading cause of death under age 50?",
    ssid: 528,  
    answers: [
      {
        answerText: "Car accidents",
        explanation: "INCORRECT. The rate of fatal car accidents has been declining for decades -- because people like you are buckling your seat belts and using designated drivers. Nice work!",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Overdoses.",
        explanation: "CORRECT. If you feel you or a dependent family member have an addiction, please use our completely confidential and free Employee Assistance Program. Don't put it off. Do it yesterday. [Note to administrators: you may add the EAP contact info in the link.]",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Heart attacks.",
        explanation: "INCORRECT. Death rates from heart attacks have been declining for years. But you still need to exercise and eat right. (That's why they have been declining.)",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "The Patriots winning yet another Super Bowl.",
        explanation: "INCORRECT. Though generally not fatal in most cases, this is nonetheless a source of great stress for the 90% of us who don't live in New England.",
        isCorrect: false,
        wasSelected: false            
      },    
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: null,
    harvardApproved: false
  },
]

export default opioidsEmployee