import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import HomePageQuiz from './Quizzes/HomePageQuiz.js'
import OpioidsEmployerQuiz from './Quizzes/OpioidsEmployerQuiz.js'
import OpioidsEmployeeQuiz from './Quizzes/OpioidsEmployeeQuiz.js'
import AarpEeocQuizFirstScreen from './Quizzes/AarpEeocQuizFirstScreen.js'
import AarpEeocQuiz from './Quizzes/AarpEeocQuiz.js'
import ContactUs from './Quizzes/ContactUs'
import {
  Conference,
  CorporateHealthLiteracy,
} from './Quizzes'

class App extends React.Component {
  render() {
    return (
      <div style={{ flexGrow: 1, }}>
        <meta name="viewport" content="initial-scale=1, maximum-scale=1" />    
        <Router>
          <Switch>
            <Route exact path="/" component={HomePageQuiz} />
            <Route path="/opioids-employer" component={OpioidsEmployerQuiz} />
            <Route path="/opioids-employee" component={OpioidsEmployeeQuiz} />       
            <Route path="/aarp-eeoc-start" component={AarpEeocQuizFirstScreen} />
            <Route path="/aarp-eeoc" component={AarpEeocQuiz} />
            <Route exact path="/perk" component={Conference} />
            <Route exact path="/conference" component={Conference} />
            <Route exact path="/corporate-health-literacy" component={CorporateHealthLiteracy} />
            <Route exact path="/contact" component={ContactUs} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App
