import React from 'react'
import styled from 'styled-components'
import Tooltip from './Tooltip'
import mediaQueries from '../mediaQueries'
import logo from './hms-logo.png'

const ShieldContainer = styled.div`
  float: right;
  ${mediaQueries.small} {
    width: 100%;
    text-align: left;
  }
`

const ShieldImage = styled.img`
  flex-shrink: 0;
  height: 27px;
  margin-left: 15px;
  cursor: help;
  ${mediaQueries.small} {
    height: 22px;
    margin-left: 0px;
    margin-bottom: 8px;
  }
`

class Shield extends React.Component {
  state = {
    showHarvardTooltip: false,
  }

  render() {
    if (this.props.visible) {
      return (
        <ShieldContainer>
          <ShieldImage
            onMouseEnter={() => this.setState({ showHarvardTooltip: true, })}
            onMouseLeave={() => this.setState({ showHarvardTooltip: false, })}
            src={logo}
            tabIndex={0}
            onFocus={() => this.setState({ showHarvardTooltip: true, })}
            onBlur={() => this.setState({ showHarvardTooltip: false, })}
            alt="Harvard Medical School" />
            {this.state.showHarvardTooltip &&
              <Tooltip text="Quizzify topics and content are reviewed by Harvard Medical School faculty physicians." />}
        </ShieldContainer>
      )
    }
    return null
  }
}

export default Shield
