import React from 'react'
import '../../App.css'
import NextButton from './NextButton'
import PreviousButton from './PreviousButton'

const NavigationButtons = (props) => {
  const {
    questionIndex,
    numberQuestions,
  } = props

  if (questionIndex === 0) {
    return (
      <div className="buttonContainer"> 
        <NextButton {...props} />
      </div>
    )
  } else if (questionIndex < numberQuestions) {
    return (
      <div className="buttonContainer"> 
        <PreviousButton {...props} />
        <NextButton {...props} />
      </div>
    )
  } else {
    return (
      <div className="buttonContainer"> 
        <PreviousButton {...props} />
      </div>
    )
  }
}

export default NavigationButtons
