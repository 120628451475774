export default [
  {
    questionText: "How does the radiation in a CT scan compare to the radiation in an x-ray?",
    answers: [
      {
        answerText: "None. CT scans use a different technology involving no radiation.",
        explanation: "INCORRECT.They emit far more radiation than an x-ray.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "More than 100 times as much.",
        explanation: "CORRECT. We aren't advising you never to get a CT scan. Just ask your doctor about the risks first. This is especially true for children, who are more susceptible to the radiation.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Usually 5 to 50 times as much.",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "About the same.",
        explanation: "INCORRECT. It's much more.",
        isCorrect: false,
        wasSelected: false
      }
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://khn.org/news/heavy-use-of-ct-scans-raises-concerns-about-patients-exposure-to-radiation/",
    harvardApproved: true 
  },
  {
    questionText: "Which is true about long-term daily use of the popular heartburn pills Nexium, Prilosec, Prevacid and Protonix?",
    answers: [
      {
        answerText: "You should take them every day for best control, but if you miss a day, don't double-up the next day.",
        explanation: "INCORRECT. While not doubling-up is excellent advice for most drugs, you should not be taking these pills every day a long period. Read the label and the Learn More link.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Suspected long-term risks not listed on the label include bone fractures, kidney damage, Vitamin B12 deficiency, dementia, bacterial infection, heart attack and premature death.",
        explanation: "CORRECT. The labels instruct users to discontinue after two weeks. These pills are safe if taken as directed, but most of us don't take them as directed. We get relief so we keep using them.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "You may experience flatulence. This is a sign that the dose may need to be reduced.",
        explanation: "INCORRECT. You can't attribute flatulence to heartburn pills. A popular alternative strategy, recommended by experts, is to blame the dog.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Long-term use can lead to baldness in males or thinning hair in females.",
        explanation: "INCORRECT. If you go bald while you're taking heartburn pills, you would have gone bald anyway.",
        isCorrect: false,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.sciencedaily.com/releases/2017/07/170705113546.htm",
    harvardApproved: true
  },
  {
    questionText: "Which is FALSE about back surgery?",
    answers: [
      {
        answerText: "Ten years post-op, the average patient reported the same amount of pain as before the surgery.",
        explanation: "INCORRECT. This is TRUE, on average. Some report relief, while others report they are worse off.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "It was widely reported that an informal survey of back surgeons at a Florida conference showed 99% saying they would never get a spinal fusion.",
        explanation: "INCORRECT. This is TRUE. While this particular survey data was sketchy, it is true that the more people know about spinal fusions, the less likely they are to get one.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Walmart reported that more than a third of its employees recommended for back surgery turned out not to need it.",
        explanation: "INCORRECT. This is TRUE. Walmart now requires a visit to a specified Center of Excellence before covering back surgery, for just that reason. These Centers find that many people told they need this surgery, don't.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Like everything else, early detection is best. Patients who undergo surgery the soonest after their diagnosis have the best outcomes.",
        explanation: "CORRECT. This is FALSE. Just the opposite. Exhaust all conservative therapies before surgery. Conservative therapies have by far the best outcomes. The Learn More link is quite opinionated and controversial, but should be read before getting back surgery.",
        isCorrect: true,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://qz.com/1010259/the-100-billion-per-year-back-pain-industry-is-mostly-a-hoax/",
    harvardApproved: false
  },
  {
    questionText: "Got stable angina? Which of the following are true about stents?",
    answers: [
      {
        answerText: "They are a breakthrough for people with clogged arteries.",
        explanation: "INCORRECT. Those done for people with unstable angina (unpredictable chest pain at rest) in true emergencies, like a heart attack, save lives. They don't benefit most people with stable angina.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "They appear to provide no benefit for patients with stable angina.",
        explanation: "CORRECT. 'Sham surgery' experiments, where some patients get stents and the others only get surgical incisions, show no difference in outcomes.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Even though the procedure offers great quality-of-life benefits, the risks are also very high.",
        explanation: "INCORRECT. The procedure offers little to no benefit, but the risks are also low. ANY type of surgery is risky, and there is no point taking even a low risk for no benefit. The biggest risk -- close to 100% -- is that they don't help.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Implanting stents is one of the fastest-growing procedures in America.",
        explanation: "INCORRECT. The number has been falling, as more patients and researchers question the benefits in many cases.",
        isCorrect: false,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.nytimes.com/2017/11/02/health/heart-disease-stents.html",
    harvardApproved: true
  },
  {
    questionText: "What are the biggest hazards of MRIs?",
    answers: [
      {
        answerText: "Radiation, dizziness, and fillings popping out.",
        explanation: "INCORRECT. MRIs don't have radiation. You may get claustrophobic but few people report dizziness. And if you filling pops out during an MRI, it was about to pop out anyway.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Misdiagnosis, contrast media, and claustrophobia.",
        explanation: "CORRECT. MRIs notoriously 'find' far more diagnoses than exist. Contrast media, called Gadolinium, is used only some of the time. It is very controversial, and we recommend inquiring about its risk before agreeing to it. Many people report discomfort at being confined in such a narrow tube.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Credit cards being demagnetized, radiation, and claustrophobia.",
        explanation: "INCORRECT. Your credit cards will be safely in the other room. MRIs emit no radiation. Some people do experience discomfort in the narrow MRI tube. You can request an Open MRI but the images won't be as precise.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Radiation is the only common hazard, and if you only get 1 or 2 MRIs in a lifetime you'll likely be just fine.",
        explanation: "INCORRECT. MRIs don't involve radiation. The biggest hazard is being 'over-diagnosed,' and then getting treated for issues that aren't the cause of your symptoms.",
        isCorrect: false,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.sciencedaily.com/releases/2017/08/170807120513.htm",
    harvardApproved: false
  },
]