import React from 'react'
import Background from '../Background'
import Container from '../Container'
import Tile from './Tile'
import warning from './warning.png'
import route from './route.png'
import Quiz from '../Quiz'
import Rules from './Rules'
import ContactUsPage from '../ContactUsPage'

class Conference extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      quizSelected: false,
      showRules: false,
    }
  }
  
  render() {
    const { quizSelected, showRules, } = this.state

    if (showRules) {
      return (
        <Background>
          <Container>
            <Rules onClick={() => this.setState({ showRules: false, })} />
          </Container>
        </Background>
      )
    }
    if (quizSelected) {
      return (
        <Background>
          <Quiz
            quizName={quizSelected}
            renderLastScreen={props => (
              <ContactUsPage
                type="conference"
                quiz={{
                  name: "Conference Quiz",
                  score: props.totalPoints,
                }}
                submitText="Submit Your Score" />
            )}
          />
        </Background>
      )
    }
    return (
      <Background subtitle="Try a Quiz">
        <Container>
          <Tile
            title="Everyday Health Hazards"
            description="Better living through trivia."
            icon={route}
            onClick={() => this.setState({ showRules: true, quizSelected: 'launchQuizConf', })} />
          <Tile
            title="Navigating the Healthcare System"
            description="Just because it's healthcare doesn't mean it's good for you."
            icon={warning}
            onClick={() => this.setState({ showRules: true, quizSelected: 'secondQuizConf', })} />
        </Container>
      </Background>
    )
  }
}

export default Conference
