export default [
  {
    questionText: "Are eggs good for you?",
    answers: [
      {
        answerText: "Yes. They are a tasty and inexpensive source of protein and many vitamins and minerals.",
        explanation: "CORRECT. Eggs got an unfair rap decades ago. Most people can eat an egg a day without raising cholesterol. To keep your cholesterol down, avoid fatty meats, and fatty dairy, like ice cream. It is also suspected that sugar raises cholesterol. So eggs for breakfast may be better than most cereals.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Kind of. They have vitamins and protein, but are full of cholesterol.",
        explanation: "INCORRECT. Foods containing cholesterol do NOT raise most people's cholesterol levels noticeably. Saturated fat -- found in red meat and dairy -- raises cholesterol. (Sugar may have an impact too.)",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Yes, if you eat the eggwhites only. The yolk has the cholesterol, not the white.",
        explanation: "INCORRECT. It is perfectly OK to eat the yolk -- that's where many of the vitamins are.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "No. Eggs have over 200 milligrams of cholesterol. That is more than you should eat in a day.",
        explanation: "INCORRECT. Unless you have a family history of abnormally high cholesterol, eating about an egg a day does not noticeably increase cholesterol in your blood.",
        isCorrect: false,
        wasSelected: false                
      },
      {
        answerText: "Only if you buy the \"free range\" eggs, which can be very expensive.",
        explanation: "INCORRECT. There are good reasons to pay extra to support humanely raised chickens, but better nutrition has never been proven to be among them.",
        isCorrect: false,
        wasSelected: false
      }
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "http://www.webmd.com/diet/features/good-eggs-for-nutrition-theyre-hard-to-beat#1",
    harvardApproved: false    
  },
  {
    questionText: "Which is true about Cinnabons?",
    answers: [
      {
        answerText: "The number of calories in just one is between about a third and a half of most people's recommended daily total calories.",
        explanation: "CORRECT. About 880 calories apiece. Many entire meals don't contain 880 calories.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "You'd have to walk for about an hour to walk off all the calories in just one.",
        explanation: "INCORRECT. That won't even get you close -- the correct answer is over three hours.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "They are full of cholesterol.",
        explanation: "INCORRECT. Despite being the junkiest of junk foods, Cinnabons and most other pastries contain very little cholesterol. Now, don't be fooled -- they are still terrible for you, and eating cholesterol doesn't raise your cholesterol levels, anyway.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Before getting his first TV role, the actor who plays the lawyer in 'Breaking Bad' and 'Better Call Saul' managed a Cinnabon in Omaha.",
        explanation: "INCORRECT. When Saul Goodman was about to skip town, his last words to Walter White were: \"Month from now, best-case scenario, I'll be managing a Cinnabon in Omaha.\" But that was the character, not the actor.",
        isCorrect: false,
        wasSelected: false                
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.calorieking.com/foods/calories-in-bakery-classic-roll_f-ZmlkPTE3MDM5Nw.html",
    harvardApproved: false
  },
  {
    questionText: "Can you fix a cavity without drilling it and filling it?",
    answers: [
      {
        answerText: "Obviously not. Everyone knows you need to drill out the decay to fill it.",
        explanation: "INCORRECT. That might have been true five years ago.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Yes. A lot of times they just go away on their own if you brush really well with a fluoride toothpaste.",
        explanation: "INCORRECT. Few dental problems go away on their own. You can't brush new cavities away.",
        isCorrect: false,
        wasSelected: false                
      },
      {
        answerText: "Yes. You can ask your dentist to sprinkle a magic potion on it.",
        explanation: "INCORRECT. But this is not far off. It exists, but dentists make so little money off it (because they charge so little for it) that you may have to ask for it.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Yes. You can ask your dentist to sprinkle a special solution on the cavity that will fix it as well as a filling would-- if you don't mind that your tooth will turn black.",
        explanation: "CORRECT. This solution is silver diamine fluoride. It is FDA-approved, and is less expensive than fill-and-drill. It will turn the decay black, so is probably best for back teeth, or children's teeth that are going to fall out anyway. No needles or drilling needed.",
        isCorrect: true,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.kidscaredental.com/blog/silver-diamine-fluoride/",
    harvardApproved: true
  },
  {
    questionText: "The first ingredient in a Clif Bar is 'organic brown rice syrup.' Which is true about that?",
    answers: [
      {
        answerText: "This is an excellent way to naturally sweeten an energy bar without added sugar.",
        explanation: "INCORRECT. This Is Sugar.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "This is the most creative synonym for sugar we have ever seen.",
        explanation: "CORRECT. Other sugary ingredients include evaporated cane juice, organic cane syrup, and barley malt extract. For snacks, a long ingredient list means lots of hidden sugars.",
        isCorrect: true,
        wasSelected: false                
      },
      {
        answerText: "Because it's organic, the anti-oxidants aren't destroyed in the processing.",
        explanation: "INCORRECT. Sugars can be organic, but you still shouldn't gobble them down like, well, candy. There might be a few anti-oxidant molecules in Clif Bars, but it doesn't matter because they are full of sugar.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "This ingredient shows the company's dedication to wholesome goodness.",
        explanation: "INCORRECT. There is nothing 'good' about sugar as a first ingredient.",
        isCorrect: false,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: null,
    harvardApproved: false
  },
  {
    questionText: "Which of the following is NOT one of the 6000 hazardous chemicals found in cigarette smoke?",
    answers: [
      {
        answerText: "Butane.",
        explanation: "INCORRECT. Butane is found in cigarette smoke, and also found in lighter fluid.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Acetone.",
        explanation: "INCORRECT. Acetone is one of the 6000, and is also what gives nail polish remover that strong odor.",
        isCorrect: false,
        wasSelected: false                
      },
      {
        answerText: "Tar.",
        explanation: "INCORRECT. The very same tar we drive on is the \"tar\" in cigarettes.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Dihydrogen monoxide.",
        explanation: "CORRECT. That's water. It's not a \"harmful chemical\" unless you're drowning, in which case most wellness experts would agree that smoking cessation should probably not be your #1 priority.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Lead.",
        explanation: "INCORRECT. It's no longer allowed in gasoline or paint. But it is allowed in cigarettes. Is this a great country or what?",
        isCorrect: false,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "http://www.lung.org/stop-smoking/smoking-facts/whats-in-a-cigarette.html",
    harvardApproved: true
  },
]