import React from 'react';
import '../App.css'

const  AarpEeocQuizFirstScreen = () => (
  <div style={{ backgroundColor: 'white' }} className="firstScreenContainer">
    <h2 className="fontWeightNormal">This quiz is NOT a substitute for legal advice from your own counsel and does not constitute legal advice. Quite the opposite, we would recommend that your own counsel review this, and contact us with any questions or concerns or contrary views. It is also NOT a substitute for our typical <a href="https://app.quizzify.com/free-sign-up" className="trialLink" target="_blank" rel="noopener noreferrer">quizzes</a>, which are way more fun.</h2>    
    <p className="fontWeightNormal">ACA stands for Affordable Care Act, ADA for Americans with Disabilities Act, GINA for Genetic Information Non-Discrimination Act, and HRA for health risk assessment</p>
    <a href="/aarp-eeoc"><button>Play Quiz</button></a>
  </div>
)

export default AarpEeocQuizFirstScreen