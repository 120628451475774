import React from 'react'
import '../App.css'
import Points from './Points.js'
import NavigationButtons from './NavigationButtons'

const Footer = props => (
  <div className="footer">
    <Points {...props} />
    <NavigationButtons {...props} />
  </div>
)

export default Footer
