import React from "react";

const opioidsEmployee = [
  {
    questionText:
      "How many times are sugars listed on the label of a Quaker® Quinoa Granola Bar?",
    answers: [
      {
        answerText: "0. Quinoa and granola are naturally sweet.",
        explanation:
          "INCORRECT. Granola is sweet...but not sweet enough to make people crave it, without added sugar.",
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: "1",
        explanation: "INCORRECT. Keep going.",
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: "3",
        explanation: "INCORRECT. Almost there.",
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: "10",
        explanation:
          "CORRECT. Rule of thumb: beware of long ingredients labels. (See highlighted instances of sugar.)",
        isCorrect: true,
        wasSelected: false,
      },
    ],
    sidePanel: {
      image:
        "https://raw.githubusercontent.com/pkprosol/quizzify_demo/master/src/quaker.jpg",
      bodyText: (
        <p style={{ fontSize: "0.75em" }}>
          <strong>INGREDIENTS: </strong>
          QUINOA, YOGURT FLAVORED COATING (<span>SUGAR</span>, PALMKERNEL AND
          PALM OIL, WHEY PROTEIN CONCENTRATE, DRIED NONFAT YOGURT - HEAT TREATED
          AFTER CULTURING [CULTURED NONFAT MILK], SOY LECITHIN, NATURAL FLAVOR,
          ARTIFICIAL COLOR, CITRIC ACID, SALT), <span>GLUCOSE</span>, GRANOLA
          (WHOLE GRAIN ROLLED OATS, WHOLEGRAIN ROLLED WHEAT,{" "}
          <span>BROWN SUGAR</span>, SUNFLOWER OIL, DRIED UNSWEETENED COCONUT,{" "}
          <span>HONEY</span>, SODIUM BICARBONATE, NATURAL FLAVOR, WHEY AND WHEY
          PROTEIN CONCENTRATE), BROWN RICE CRISP (WHOLE GRAIN BROWN RICE FLOUR,{" "}
          <span>SUGAR</span>, MALTED BARLEY FLOUR, SALT), CARAMEL FLAVORED
          COATING (<span>GLUCOSE</span>, <span>SUGAR</span>, PALMOIL, NONFAT
          MILK, GLYCERIN, BUTTER (CREAM, SALT), MODIFIED CORNSTARCH, WHEY, SALT,
          MONO AND DIGLYCERIDES, NATURAL AND ARTIFICIAL FLAVOR, SOY LECITHIN),{" "}
          <span>INVERT SUGAR</span>, <span>CORN SYRUP SOLIDS</span>, ALMONDS,
          GLYCERIN, PEANUTS, DRIED SWEETENED CRANBERRY PIECES (
          <span>SUGAR</span>, DRIED CRANBERRIES, CITRIC ACID, ELDERBERRY JUICE
          CONCENTRATE [COLOR], SUNFLOWER OIL), RAISINS, SUNFLOWER KERNELS
          (SUNFLOWER SEED, SUNFLOWER OIL AND/OR COTTONSEED OIL), VEGETABLE
          SHORTENING (PALM KERNEL OIL, PALM OIL, SORBITAN TRISTEARATE, TBHQ [A
          PRESERVATIVE], CITRIC ACID), SALT, TOCOPHEROLS (PRESERVATIVE).
        </p>
      ),
      bodyTextComplete: (
        <p style={{ fontSize: "0.75em" }}>
          <strong>INGREDIENTS:</strong>
          QUINOA, YOGURT FLAVORED COATING (
          <span className="highlight-text">SUGAR</span>, PALMKERNEL AND PALM
          OIL, WHEY PROTEIN CONCENTRATE, DRIED NONFAT YOGURT - HEAT TREATED
          AFTER CULTURING [CULTURED NONFAT MILK], SOY LECITHIN, NATURAL FLAVOR,
          ARTIFICIAL COLOR, CITRIC ACID, SALT),{" "}
          <span className="highlight-text">GLUCOSE</span>, GRANOLA (WHOLE GRAIN
          ROLLED OATS, WHOLEGRAIN ROLLED WHEAT,{" "}
          <span className="highlight-text">BROWN SUGAR</span>, SUNFLOWER OIL,
          DRIED UNSWEETENED COCONUT,{" "}
          <span className="highlight-text">HONEY</span>, SODIUM BICARBONATE,
          NATURAL FLAVOR, WHEY AND WHEY PROTEIN CONCENTRATE), BROWN RICE CRISP
          (WHOLE GRAIN BROWN RICE FLOUR,{" "}
          <span className="highlight-text">SUGAR</span>, MALTED BARLEY FLOUR,
          SALT), CARAMEL FLAVORED COATING (
          <span className="highlight-text">GLUCOSE</span>,{" "}
          <span className="highlight-text">SUGAR</span>, PALMOIL, NONFAT MILK,
          GLYCERIN, BUTTER (CREAM, SALT), MODIFIED CORNSTARCH, WHEY, SALT, MONO
          AND DIGLYCERIDES, NATURAL AND ARTIFICIAL FLAVOR, SOY LECITHIN),{" "}
          <span className="highlight-text">INVERT SUGAR</span>,{" "}
          <span className="highlight-text">CORN SYRUP SOLIDS</span>, ALMONDS,
          GLYCERIN, PEANUTS, DRIED SWEETENED CRANBERRY PIECES (
          <span className="highlight-text">SUGAR</span>, DRIED CRANBERRIES,
          CITRIC ACID, ELDERBERRY JUICE CONCENTRATE [COLOR], SUNFLOWER OIL),
          RAISINS, SUNFLOWER KERNELS (SUNFLOWER SEED, SUNFLOWER OIL AND/OR
          COTTONSEED OIL), VEGETABLE SHORTENING (PALM KERNEL OIL, PALM OIL,
          SORBITAN TRISTEARATE, TBHQ [A PRESERVATIVE], CITRIC ACID), SALT,
          TOCOPHEROLS (PRESERVATIVE).
        </p>
      ),
    },
    wasQuestionCompleted: false,
    questionType: "text-analysis",
    linkAddress: null,
    harvardApproved: true,
  },
  {
    questionText: "Can you fix a cavity without drilling it and filling it?",
    answers: [
      {
        answerText:
          "Obviously not. Everyone knows you need to drill out the decay to fill it.",
        explanation: "INCORRECT. That might have been true five years ago.",
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText:
          "Yes. A lot of times they just go away on their own if you brush really well with a fluoride toothpaste.",
        explanation:
          "INCORRECT. Few dental problems go away on their own. You can't brush new cavities away.",
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText:
          "Yes. You can ask your dentist to sprinkle a magic potion on it.",
        explanation:
          "INCORRECT. But this is not far off. It exists, but dentists make so little money off it (because they charge so little for it) that you may have to ask for it.",
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText:
          "Yes. You can ask your dentist to sprinkle a special solution on the cavity that will fix it  — but your cavity will turn black.",
        explanation:
          "CORRECT. It's called SDF. It is FDA-approved, painless, and inexpensive. It turns the decay black, so is best for back teeth, or baby teeth. No needles or drilling needed. It works on 2/3 of cavities. You have to ask, though. It's too unprofitable for dentists to suggest it.",
        isCorrect: true,
        wasSelected: false,
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress:
      "https://www.nytimes.com/2016/07/12/health/silver-diamine-fluoride-dentist-cavities.html",
    harvardApproved: true,
  },
  {
    questionText:
      "How does the radiation in a CT ('CAT') scan compare to the radiation in an X-ray?",
    answers: [
      {
        answerText: "A CT scan has about 500 times as much as an x-ray.",
        explanation:
          "CORRECT. Most have between 100 and 1000 times as much radiation. We aren't saying: 'Never get a CT scan.' We are saying: 'Make sure to consider the risks and discuss them with your doctor.' This is especially true for your children.",
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: "A CT scan has about 5 times as much as an x-ray.",
        explanation:
          "INCORRECT. You’re not gonna believe this, but it’s a lot worse. Try again.",
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: "They are about the same.",
        explanation:
          "INCORRECT. You’re not gonna believe this, but it’s a lot worse. Try again.",
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText:
          "The X-ray has more. CT technology doesn’t involve radiation at all.",
        explanation:
          "INCORRECT. Alas, no. Perhaps on some other planet, but here on earth, CT Scans involve lots of radiation. Try again to guess how much. (Hint: it's a lot!)",
        isCorrect: false,
        wasSelected: false,
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress:
      "http://khn.org/news/heavy-use-of-ct-scans-raises-concerns-about-patients-exposure-to-radiation/",
    harvardApproved: true,
  },
  {
    questionText:
      "Long-term daily use of heartburn pills like Nexium, Prilosec or Prevacid could increase your risk of:",
    answers: [
      {
        answerText: "Kidney disease.",
        explanation:
          "CORRECT. Ask your doctor about Pepcid or Tums. That's what we take here at Quizzify. Regular use of Nexium-type drugs has too many suspected long-term side effects for us to take the chance. See the link.",
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: "Bone fractures (especially if you are female).",
        explanation:
          "CORRECT. Especially if you have thin or thinning bones, ask your doctor about Pepcid or Tums. Or try active-culture yogurt to restore the natural bacteria to your stomach.",
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: "Flatulence.",
        explanation: "INCORRECT. Experts recommend blaming the dog.",
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText:
          "Heart attacks (especially if you are high-risk to begin with).",
        explanation:
          "CORRECT. Ask your doctor about switching to Pepcid or Tums. Try active-culture yogurt. Most of all, see for yourself, starting with the link.",
        isCorrect: true,
        wasSelected: false,
      },
      {
        answerText: "Ring Around the Collar.",
        explanation:
          "INCORRECT. And if it does, there is an easy solution – Wisk around the collar beats Ring Around the Collar every time. (See YouTube for more details.)",
        isCorrect: false,
        wasSelected: false,
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress:
      "http://www.npr.org/sections/health-shots/2015/06/11/413433350/data-dive-suggests-link-between-heartburn-drugs-and-heart-attacks",
    harvardApproved: true,
  },
  {
    questionText:
      "What is the phone number for our completely confidential Employee Assistance Program?",
    answers: [
      {
        answerText: "867-5309",
        explanation: "INCORRECT. But send our regards to Jenny.",
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: "Beechwood 4-5789",
        explanation:
          "INCORRECT. But you can get a date with the Marvelettes, any old time.",
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: "Call Directory Assistance.",
        explanation: "INCORRECT: What’s “Directory Assistance”?",
        isCorrect: false,
        wasSelected: false,
      },
      {
        answerText: "[You can enter the correct number here]",
        explanation:
          "CORRECT. Enter this number into your phone in case you or a family member ever need help with issues ranging from alcohol or substance abuse to child or elder care, financial or legal support, or counseling for traumatic events. See the link for the full range of services.",
        isCorrect: true,
        wasSelected: false,
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress:
      "https://www.shrm.org/resourcesandtools/tools-and-samples/hr-qa/pages/whatisaneap.aspx",
    harvardApproved: false,
  },
];

export default opioidsEmployee;
