import React from 'react'
import styled from 'styled-components'
import ThankYou from './ThankYou'
import SubmissionError from './SubmissionError'
import ContactUs from './ContactUs'
import mediaQueries from './mediaQueries'

const Message = styled.h2`
  color: #555555;
  font-family: avenir;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 1.2rem;
  ${mediaQueries.mobile} {
    font-size: 1.0rem;
  }
`

class ContactUsPage extends React.Component {
  state = {
    showThankYou: false,
    submissionError: false,
  }

  handleSuccessfulSubmit = () => this.setState({ showThankYou: true, submissionError: false, })
  handleUnsuccessfulSubmit = () => this.setState({ submissionError: true, showThankYou: false, })

  render() {
    const isConference = this.props.type === "conference"

    const { showThankYou, submissionError, } = this.state
    if (submissionError) {
      return <SubmissionError isConference={isConference} />
    }

    if (showThankYou) {
      return <ThankYou isConference={isConference} />
    }

    return (
      <div
        className="appContainer"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '0 15px 25px 15px',
          ...(isConference ? {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          } : {})
        }}>
        {isConference ? (
          <React.Fragment>
            <p style={{ fontSize: 35, textAlign: 'center', color: 'white', margin: 0, }}>
              Well done! Your score is&nbsp;
              <span style={{ color: '#FFF67E', }}>
                {this.props.quiz.score}
              </span>.
            </p>
            <p style={{ fontSize: 25, textAlign: 'center', color: 'white', margin: '1em 0 0 0', }}>
              Enter your email to submit your score.
            </p>
            <div style={{ height: 25, }} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h1 style={{ fontFamily: 'didot', color: '#555555', marginBottom: 10, marginTop: 0, paddingTop: 20, fontSize: 50, }}>
              Quizzify
            </h1>
            <Message>Employee health literacy, one quiz at a time.</Message>
            <Message>Wiser employees make healthier decisions.</Message>
            <Message style={{ marginBottom: 30, }}>Request a FREE demo.</Message>
          </React.Fragment>
        )}
        <ContactUs
          {...this.props}
          onSuccessfulSubmit={this.handleSuccessfulSubmit}
          onUnsuccessfulSubmit={this.handleUnsuccessfulSubmit} />
      </div>
    )
  }
}

export default ContactUsPage
