import React from 'react'
import Button from './Button'



const NextButton = ({ onClickNext, currentQuestion, }) => {
  const isComplete = currentQuestion && currentQuestion.wasQuestionCompleted

  return (
    <div style={{ width: 150, marginLeft: 8, marginRight: 8, }}>
      <Button
        disabled={!isComplete}
        onClick={onClickNext}>
        Next
      </Button>
      {!isComplete && (
        <p style={{
          fontSize: 13,
          textAlign: 'center',
          marginTop: 8,
          marginBottom: 0,
          lineHeight: '1.2em',
          paddingLeft: 10,
          paddingRight: 10,
        }}>
          Select all correct answers to continue
        </p>
      )}
    </div>
  )
}

export default NextButton
