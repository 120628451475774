import homePage from './homePage'
import opioidsEmployer from './opioidsEmployer'
import opioidsEmployee from './opioidsEmployee'
import aarpEeoc from './aarpEeoc'
import launchQuiz from './launchQuiz'
import summerQuiz from './summerQuiz'
import launchQuizConf from './launchQuizConf'
import secondQuizConf from './secondQuizConf'
import corporateHealthLiteracyFoundations from './corporateHealthLiteracyFoundations'

function quizContent(name) {
  switch(name) {
    case 'homePage':
      return homePage
    case 'opioidsEmployer':
      return opioidsEmployer
    case 'opioidsEmployee':
      return opioidsEmployee
    case 'aarpEeoc':
      return aarpEeoc
    case 'launchQuiz':
      return launchQuiz
    case 'launchQuizConf':
      return launchQuizConf
    case 'secondQuizConf':
      return secondQuizConf
    case 'summerQuiz':
      return summerQuiz
    case 'CorporateHealthLiteracy':
      return corporateHealthLiteracyFoundations
    default:
      // return home
  }
}

export default quizContent