import React from 'react'
import styled from 'styled-components'

const Message = styled.h2`
  font-family: avenir;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: ${props => props.isConference ? 'white' : 'black'};
`

const ThankYou = ({ isConference, }) => (
  <div
    className="appContainer"
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      ...(isConference ? {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      } : {})
    }}>
    <Message isConference={isConference}>
      Thank you for contacting Quizzify.
    </Message>
    <Message isConference={isConference}>
      We&apos;ll be in touch soon!
    </Message>
  </div>
)

export default ThankYou
