import React from 'react';
import '../App.css';

function Points(props) {
  if (props.isLastScreen) {
    return (
      <div className="scoreBoard">
        <div className="footerContainer"> 
          <p className="footerLabel">Points (Quiz Total)</p>
          <p className="scoreDisplay">{props.totalPoints}</p>
        </div>
      </div>
    );    
  } else {
    return (
      <div className="scoreBoard">
        <div className="footerContainer">
          <p className="footerLabel">Points (Current Question)</p>
          <p className="scoreDisplay" style={props.pointsStyle}>
            {props.currentPoints}
          </p>
        </div>
        <div className="footerContainer"> 
          <p className="footerLabel">Points (Quiz Total)</p>
          <p className="scoreDisplay">
            {props.totalPoints}
          </p>
        </div>
      </div>
    ); 
  }
}

export default Points;