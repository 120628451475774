import React from 'react'
import styled from 'styled-components'
import Button from './Button'

const mobile = '@media only screen and (max-width: 800px)'

const Container = styled.div`
  width: 450px;
  height: 350px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  margin: 20px;
  box-shadow: 0px 0px 10px 0px white;
  cursor: pointer;
  font-family: avenir;
  max-height: 75vh;
  max-width: 85vw;
  ${mobile} {
    height: auto;
    width: 100%;
    max-width: 85vw;
    margin: 10px;
    box-sizing: border-box;
  }
`

const H2 = styled.h2`
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.4em;
  margin: 0;
  ${mobile} {
    font-size: 24px;
  }
`

const H4 = styled.h4`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  font-family: avenir-light;
  line-height: 1.4em;
  margin: 0;
  ${mobile} {
    font-size: 18px;
  }
`

const Tile = ({ title, description, icon, onClick, }) => (
  <Container onClick={onClick}>
    <div style={{
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      flexShrink: 0,
    }}>
      <div style={{ marginBottom: 15, marginTop: 15, }}>
        <img
          alt="quiz-icon"
          height={90}
          width="auto"
          src={icon} />
      </div>
      <H2>{title}</H2>
      <div style={{ height: 15, }} />
      <H4>{description}</H4>
    </div>
    <div style={{ height: 30, flexShrink: 0, }} />
    <Button>
      PLAY
    </Button>
  </Container>
)

export default Tile
