export default [
  {
    questionText: "What is the relationship between cost and quality in the U.S. medical marketplace?",
    answers: [
      {
        answerText: "No clear, consistent, relation.",
        explanation: "CORRECT. It will vary from facility to facility, but in general it is believed that lower cost and higher quality go together.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Obviously, the more you pay, the more you get, like everything else.",
        explanation: "INCORRECT. The debate is whether there is any relation at all, or whether lower-cost venues have higher quality. No one argue that the more you pay, the more you get. Often you are paying for complications. The more errors a hospital makes, the more it can charge.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "More likely to be that cost is not related to quality, or even that lower cost can be higher quality.",
        explanation: "CORRECT. High-volume facilities usually have lower costs and fewer complications that would create even more cost.",
        isCorrect: true,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: '',
    harvardApproved: false
  },
  {
    questionText: "In the hospital services component of your healthcare supply chain, private commercial insurers pay what percentage of what the government pays for provider services?",
    answers: [
      {
        answerText: "25% less than the government because they are able to negotiate as a business and have a bottom line to protect.",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "The same, by law.",
        explanation: "NCORRECT. Laws would require lawmakers to turn down contributions from the industries they are supposed to be making laws about.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "30% more because there are brokers and middlemen involved, meaning that the government price gets marked up.",
        explanation: "INCORRECT. Not even close.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "2 to 10 times as much as the government pays.",
        explanation: "CORRECT. The worst is dialysis, where private insurers have no leverage if a town only has one dialysis provider.",
        isCorrect: true,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.cbo.gov/system/files/115th-congress-2017-2018/workingpaper/53441-workingpaper.pdf",
    harvardApproved: true
  },
  {
    questionText: "Major reasons employees not provided with a health literacy tool like Quizzify demand expensive medical care are:",
    answers: [
      {
        answerText: "They don't understand the risks (for instance, a CT scan exposes patients to 100 to 1000 times as much radiation as an x-ray).",
        explanation: "CORRECT. Generally the risks are not disclosed.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "They don't understand false positives, so they demand expensive scans without realizing that scans find many problems that don't exist that doctors will want to treat.",
        explanation: "CORRECT. They don't understand false positives, so they demand expensive scans without realizing that scans ìfindî many problems that don't exist that doctors will want to treat.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "They get a second opinion from a qualified specialist who is NOT affiliated with the doctor who recommended the procedure.",
        explanation: "INCORRECT. This is TRUE. Walmart now requires a visit to a specified Center of Excellence before covering back surgery, for just that reason. These Centers find that many people told they need this surgery, don't.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Their co-pays are very low relative to the price of the intervention so there is no real economic reason not to demand the high-cost intervention and they don't understand the health-and-safety reasons not to demand the high-cost intervention.",
        explanation: "CORRECT. Employees don't realize that just because it's healthcare doesn't mean it's good for you.",
        isCorrect: true,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.thespinejournalonline.com/article/S1529-9430(16)31093-2/fulltext",
    harvardApproved: true
  },
  {
    questionText: "If a new drug is approved by the FDA, you can be sure that:",
    answers: [
      {
        answerText: "It was tested on thousands of people.",
        explanation: "IINCORRECT. The number that a drug is tested on can be in the thousands. But drugs for serious diseases are often tested only on dozens of people before going to market.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "While it may not and probably doesn't cure the disease in question, the drug will improve quality of life more than older drugs.",
        explanation: "INCORRECT. The FDA is not making a comparative judgment. The standard for approval is whether the benefits may exceed the harms for some patients. Patients respond differently to drugs, so there is zero guarantee (for most new drugs) that they will work better than old ones. See the link.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "The new drug will be more cost-effective than other drugs on the market.",
        explanation: "INCORRECT. The FDA doesn't address issues of cost. New drugs often cost far more than older ones.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "None of the above.",
        explanation: "CORRECT. People tend to think that FDA \"approval\" equates to FDA \"endorsement.\" The standard for FDA approval is that the drug does what it claims to do, and benefits should exceed harms for some patients. The days of new drugs being miracle cures are far behind us. Only one disease (Hepatitis C) is now cured by a drug that wasn't available ten years ago.",
        isCorrect: true,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://www.healthaffairs.org/do/10.1377/hblog20171021.268271/full/",
    harvardApproved: false
  },
  {
    questionText: "Which of the following is one of the top ten hospital costs for employers, one of the most avoidable, and has the highest complication rate of any common hospitalization?",
    answers: [
      {
        answerText: "Knee replacements.",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Spinal fusions.",
        explanation: "CORRECT. See the link. They fail much of the time, leaving the patient much worse off. It was reported that at a conference 99% of back surgeons said they would never get back surgery for themselves. This \"report\" has never been independently verified but the failure rate of back surgery is undeniably high.",
        isCorrect: true,
        wasSelected: false
      },
      {
        answerText: "Caesarean sections.",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "Bariatric surgery.",
        explanation: "INCORRECT.",
        isCorrect: false,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: "https://qz.com/1010259/the-100-billion-per-year-back-pain-industry-is-mostly-a-hoax/",
    harvardApproved: false
  },
  {
   questionText: "Which are true about the FDA?",
    answers: [
      {
        answerText: "To be approved, drugs have to work most of the time.",
        explanation: "INCORRECT. They have to be safe, and perform as their label says they perform.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "They don't have to work any better than alternatives, if they are more cost-effective.",
        explanation: "INCORRECT. Cost does not factor into FDA approvals.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "The labels must clarify to doctors which drug is the best medication for which patients.",
        explanation: "INCORRECT. And once a drug is approved, a doctor may prescribe it for any patient, not just the ones on which the research was done. This common practice is called \"off-label\" prescribing.",
        isCorrect: false,
        wasSelected: false
      },
      {
        answerText: "The best selling FDA approved drug ever made ($18B/yr) produces no improvement in 49% of people who take this drug and a 50% or better improvement in only 24% of the people who take this drug.",
        explanation: "CORRECT. Humira.",
        isCorrect: true,
        wasSelected: false
      },
    ],
    wasQuestionCompleted: false,
    questionType: "basic",
    linkAddress: '',
    harvardApproved: false
  },
]
