import React from 'react'

const ProgressBar = ({ percent, }) => {
  if (percent > 0) {
    return (
      <div style={{ width: '100%', height: 25, backgroundColor: 'lightgray', borderRadius: 5, }}>
        <div style={{
          height: '100%',
          width: `${percent}%`,
          backgroundColor: '#138a13',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          { (
            <p style={{ color: 'white', fontWeight: 'bold', margin: 0, }}>
              {Math.round(percent)}%
            </p>
          )}
        </div>
      </div>
    )
  }
  return null
}

export default ProgressBar